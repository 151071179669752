<template>
  <div class="">
    <div class="vx-col w-full mt-base job-listing">
      <vx-card title="Book Session">
        <p v-if="services.length === 0">No learning sessions listed yet!</p>
        
        <view-service-popup ref="viewPopup" />
        
        <div class="" v-for="(ss, index) in services" :key="index">
          <div>
            <h5 class="d-flex align-items-center">
              <feather-icon 
                class="mr-2 text-primary"
                icon="VideoIcon"
                svgClasses="w-5 h-5"
              />
              <a href="#" target="_blank">{{ ss.title }}</a>
            </h5>
          </div>
          <div class="mt-2">
            <span class="flex mb-1">
              <feather-icon
                icon="BookIcon"
                class="mr-2"
                svgClasses="h-4 w-4"
              />
                <span>
                    <b>Type:</b>
                    {{ ss.servicesOffered.toString() }}
                </span>
            </span>

            <span class="flex mb-1">
              <feather-icon
                icon="BookIcon"
                class="mr-2"
                svgClasses="h-4 w-4"
              /><span
                ><b>Subjects:</b>
                {{ ss.subjects.toString() }}</span
              ></span
            >

            <span class="flex mb-1">
              <feather-icon
                icon="DollarSignIcon"
                class="mr-2"
                svgClasses="h-4 w-4"
              /><span 
                ><b>Pricing:</b>

                <span v-if="ss.packages.items[0].price == 0 && ss.packages.items[0].discountPrice == 0" >
                    <span class="text-primary ml-2">Free</span>
                  </span>
                  <span v-else >
                    <span  v-if="ss.packages.items[0].discountPrice"  class="text-success ml-2"
                      >${{ ss.packages.items[0].discountPrice }}</span
                    ><span
                      class="text-primary ml-2"
                      v-if="ss.packages.items[0].price"
                      :class="
                        ss.packages.items[0].discountPrice
                          ? 'line-through'
                          : ''
                      "
                      >${{ ss.packages.items[0].price }}</span
                    >
                  </span>
              </span
              
              ></span
            >

          <div class="mt-4 text-center">
            <vs-button size="small" @click="toggleBookingPopup(ss, index)">Book Now</vs-button>
          </div>
          </div>

          <div class="mt-2">
            <hr />
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { API, graphqlOperation } from "@aws-amplify/api";
import { Storage } from '@aws-amplify/storage'

import {
  getCourseFeedSubscriber,
  educatorServicesByEducatorId,
  educatorByUser,
  sessionBookingByServiceId,
  getUser
} from '@/graphql/queries'
import ViewServicePopup from '@/views/profile/partner/EducatorServices/ViewServicePopup'
import { createErrorObject, createSuccessObject } from '@/utils'


export default {
  name: "ServicesListing",
  components: {
    ViewServicePopup
  },
  data() {
    return {
      services: [],
    };
  },
  methods: {
    /*
    async fetchServices() {
      try {
        const cfRes = await API.graphql(graphqlOperation(getCourseFeedSubscriber, {
          id: this.$route.params.id
        }))
        const courseFeed = cfRes.data.getCourseFeedSubscriber.courseFeedRef

        const res = await API.graphql(
          graphqlOperation(educatorByUser, {
            user: courseFeed.user,
          })
        )
        
        if (res.data.educatorByUser.items.length === 0) {
          this.$vs.notify( 
            createErrorObject("Error", "Cannot find educator data")
          );
        }
        const educator = res.data.educatorByUser.items[0]
        
        for (const service of educator.services.items) {
          service.imageURL = []
          for (const img of service.images) {
            const url = await Storage.get(img)
            service.imageURL.push(url)
          }
        }

        this.services = educator.services.items
      } catch (err) {
        console.log("fetchServices", err);
      }
    },
    */
    async fetchServices () {
      try {
        const cfRes = await API.graphql(graphqlOperation(getCourseFeedSubscriber, {
          id: this.$route.params.id
        }))
        const courseFeed = cfRes.data.getCourseFeedSubscriber.courseFeedRef
        
        const getEducatorRes = await API.graphql(
          graphqlOperation(educatorByUser, {
            user: courseFeed.user
          })
        )

        if (!getEducatorRes.data.educatorByUser.items[0]) {
          // this.$vs.notify(
          //   createErrorObject('Error', 'Cannot find educator data')
          // )
          return
        } 

        const educator = getEducatorRes.data.educatorByUser.items[0]
        const res = await API.graphql(graphqlOperation(educatorServicesByEducatorId,{
          educatorID: educator.id,
          filter: {status: {eq: "Active"}}
        }))
        const services = res.data.educatorServicesByEducatorID.items;
        for(const s of services) {
          s.imageURL = []
          for (const img of s.images) {
            const url = await Storage.get(img);
            s.imageURL.push(url);
          }
        }  
        this.services = services  

        // for (const service of educator.services.items) {
        //   service.imageURL = []
        //   for (const img of service.images) {
        //     const url = await Storage.get(img)
        //     service.imageURL.push(url)
        //   }
        // }
        // this.services = educator.services.items
      } catch (err) {
        console.log(err)
      }
    },
    dayOfWeek (day) {
      if (day === 0) return 'Sunday'
      if (day === 1) return 'Monday'
      if (day === 2) return 'Tuesday'
      if (day === 3) return 'Wednesday'
      if (day === 4) return 'Thursday'
      if (day === 5) return 'Friday'
      if (day === 6) return 'Saturday'
    },
    async toggleBookingPopup (service, index) {
      try {
        const viewPopup = this.$refs.viewPopup
        const disabledDates = []
        const days = service.schedule.map(item => item.day)
        for (let i = 0; i < 7; i++) {
          if (!days.includes(i)) {
            disabledDates.push(i)
          }
        }
  
        viewPopup.disabledDates.days = disabledDates
        viewPopup.schedule = service.schedule
  
        viewPopup.session = service.session
        service.packages.items.map(item => { item.isSelected = false })
        viewPopup.timeslots = []
        viewPopup.serviceID = service.id
        viewPopup.selectPackage = service.packages.items[0]
        const getUserRes = await API.graphql(
              graphqlOperation(getUser, {
                name: service.educatorRef.user,
              })
        );
        viewPopup.serviceUserData = getUserRes.data.getUser;
  
        const bookingRes = await API.graphql(graphqlOperation(sessionBookingByServiceId, {
          educatorServiceID: service.id
        }))
  
        viewPopup.bookings = bookingRes.data.sessionBookingByServiceID.items
        viewPopup.active = true
      } catch (err) {
        console.log(err)
      }
    }
  },
  created() {
    this.fetchServices();
  },
};
</script>

<style lang="css">
.my-scrollmenu {
  flex-wrap: wrap !important;
}
</style>
