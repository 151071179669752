<template>
  <div ref="profilePage" id="profile-page">
    <div class="vx-row">
      <div class="vx-col w-full">
        <course-feed-tab  :isCourseFeed="true"/>
      </div>
    </div>

    <!-- COL AREA -->
    <div class="vx-row">
      <!-- COL 1 -->
      <div class="vx-col w-full lg:w-1/4 lg:order-1 order-2"> 
        <coursefeed-details v-if="dataLoaded"  /> 
      <!--   <SessionListing v-if="dataLoaded"  />  -->
        <!-- <course-review v-if="dataLoaded"  /> -->
      </div>
      <!-- COL 2 -->
      <div class="vx-col w-full lg:w-1/2 lg:order-2 order-1">
        <!-- <welcome-feeds-info  v-if="!isIntroClosed" /> -->
        <post-input-form v-if="dataLoaded && showPostForm" parentType="coursefeed" :userImage="activeUserData.photoURL" />
        <newsfeed v-if="dataLoaded" :displayShare="false"  />
      </div>
      <!-- COL 3 -->
      <div class="vx-col w-full lg:w-1/4 lg:order-3 order-3">
       <!--   <educator-feeds
        v-if="dataLoaded"  
          :author="courseFeed.user"
          :isUniEmailVerify="isUniEmailVerify"
        />  -->
    <!--     <VideoListing v-if="dataLoaded" :videoFeed="videoFeed"  />    -->

        <div class="mt-base">
          <vx-card  v-if="dataLoaded" title="Community" class=""> 
            <div class="Coursepal-mobile">
              <b-tabs pills content-class="mt-3">
                <b-tab title="Members" >
                  <suggested-follows :isCourseFeed="true" />
                </b-tab>
                <!--   <b-tab title="My Follows">
                        <MyFollows />
                       </b-tab>
                       <b-tab title="My Followers">
                        <MyFollowers /> -->
                <!-- </b-tab> -->
              </b-tabs>
            </div>
          </vx-card>
        </div>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex justify-center mt-base">
          <div
            id="button-load-more-posts"
            class="vs-con-loading__container"
          ></div>
        </div>
      </div>
    </div>

    <div>
      <Share v-if="dataLoaded"  />
    </div>
  </div>
</template>
 
<script>


import AboutUser from "@/layouts/components/coursepal/AboutUser";



import PostInputForm from "@/layouts/components/coursepal/PostInputForm";
import Newsfeed from "@/layouts/components/coursepal/Newsfeed"; 
import SuggestedFollows from "@/layouts/components/coursepal/SuggestedFollows";

import MyFollows from "@/layouts/components/coursepal/MyFollows";
import MyFollowers from "@/layouts/components/coursepal/MyFollowers";
import SessionListing from "@/views/pages/sessions/SessionListing";


import MyFeeds from "@/layouts/components/coursepal/MyFeeds";
// import EducatorFeeds from "@/layouts/components/coursepal/EducatorFeeds";
import PictureHolder from "@/layouts/components/coursepal/PictureHolder";
import VideoListing from "@/layouts/components/coursepal/VideoListing";
import Share from "@/layouts/components/coursepal/Share";
import WelcomeFeedsInfo from "@/layouts/components/coursepal/WelcomeFeedsInfo";

import { API, graphqlOperation } from '@aws-amplify/api'
import { getCourseFeedSubscriber,partnerByUser } from '@/graphql/queries'
import { getNewsfeed } from '@/graphql/getNewsfeed' 
import { Storage } from '@aws-amplify/storage'

export default {
  name: "Coursefeed",
  data() {
    return {
      isNavOpen: false,
      wasSidebarOpen: null,
      isPictureHolderLoading: false,
      courseFeed: '',
      dataLoaded: false,
      videoFeed:[],
      showPostForm : false,
    };
  },
  watch: {
    '$route.params.id' (id) {
      this.dataLoaded = false;
      this.$vs.loading({
        container: this.$refs.profilePage,
        scale: 0.5
      })
      this.getCourseFeed()
    }
  },
  computed: {
    activeUserData() {
      return this.$store.state.auth.userData;
    },
    isUniEmailVerify() {
      return this.$store.state.auth.isUniEmailVerified;
    },
    isIntroClosed() {
      return this.$store.state.auth.userData.isIntroClosed;
    },
    isFirstLogin() {
      return this.$store.state.auth.userData.isFirstLogin;
    },
  },
  components: {
 

    PostInputForm,
    Newsfeed,
    SuggestedFollows,

    MyFollows,
    MyFollowers,

    MyFeeds,
  //  EducatorFeeds,
    PictureHolder,
    Share,
    AboutUser,
    WelcomeFeedsInfo,
    SessionListing,
    VideoListing,
   
  },
  methods: {
    handleScroll() {
      if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
        this.$vs.loading({
          container: "#button-load-more-posts",
        });
        this.$store
          .dispatch("newsfeed/fetchNextPage", {
            channelID: this.$store.state.channel.currentChannel,
          })
          .then(() =>
            this.$vs.loading.close("#button-load-more-posts > .con-vs-loading")
          );
      }
    },
    async getCourseFeed () {      
      try {
        const res = await API.graphql(graphqlOperation(getCourseFeedSubscriber, {
          id: this.$route.params.id 
        }))
        this.courseFeed = res.data.getCourseFeedSubscriber
        if(this.courseFeed.courseFeedRef.user === this.$store.state.auth.userData.name){
          this.showPostForm = true;
        }else{
          this.showPostForm = false;
        }     
        this.$store.commit('channel/SET_CURRENT_CHANNEL', this.courseFeed)
        this.$store.dispatch("newsfeed/fetchPosts", {
          channel: res.data.getCourseFeedSubscriber,
          type: 'COURSE'
        })
        this.getVideoNewsfeed(this.courseFeed.user,this.courseFeed.courseFeed);
        this.dataLoaded = true;        
        this.$vs.loading.close(this.$refs.profilePage)
      } catch (err) {
        console.log('getCourseFeed', err)
      }
    },
    async getVideoNewsfeed(name,channelID){
      this.videoFeed = [];
      const result = await API.graphql(graphqlOperation(getNewsfeed, {
        name : name,
        channelID: channelID.toString(),
        limit : null,
        postNextToken  : null,
        likeNextToken  : null,
        commentNextToken  : null,
      }))
      if (!result.data.getNewsfeed.newsfeed) return [] 
      for (const feed of result.data.getNewsfeed.newsfeed) {      
        if (feed.postRef.video) {
          const url = await Storage.get(feed.postRef.video)
          feed.postRef.videoURL = url
          if (feed.postRef.authorRef.isPartner) {
            const partnerByUserRes = await API.graphql(graphqlOperation(partnerByUser, {
              user: feed.postRef.author
            }))
            feed.postRef.partnerData = partnerByUserRes.data.partnerByUser.items[0]
          }
          if (feed.postRef.sharePostID) {
            const sharePostRes = await API.graphql(graphqlOperation(getSharePost, {
              id: feed.postRef.sharePostID
            }))
            if (sharePostRes.data.getPost && sharePostRes.data.getPost.medias.length > 0) {
              const mediaFiles = sharePostRes.data.getPost.medias.map(m => getCompressImageUrl(m, 600))
              sharePostRes.data.getPost.mediaFiles = mediaFiles
            }
            if (sharePostRes.data.getPost.authorRef.isPartner) {
              const sharePostPartnerByUser = await API.graphql(graphqlOperation(partnerByUser, {
                user: sharePostRes.data.getPost.author
              }))
              sharePostRes.data.getPost.partnerData = sharePostPartnerByUser.data.partnerByUser.items[0]
            }
            feed.postRef.sharePost = sharePostRes.data.getPost
            
          }
           feed.postRef.isLiked = false
            for (const like of feed.postRef.likes.items) {
              if (like.postID === feed.postRef.id && like.user === this.$store.state.auth.userData.name) {
                feed.postRef.isLiked = true
                break
              }
            }
            feed.postRef.viewAllComments = false            
            for (const comment of feed.postRef.comments.items) {
              if (comment.likes.items.length === 0) comment.isLiked = false
              else {
                for (const like of comment.likes.items) {
                  if (like.user === this.$store.state.auth.userData.name) {
                    comment.isLiked = true
                  } else comment.isLiked = false
                }
              }

              for (const subComment of comment.subComments.items) {
                if (subComment.likes.items.length === 0) subComment.isLiked = false
                else {
                  for (const like of subComment.likes.items) {
                    if (like.user === this.$store.state.auth.userData.name) {
                      subComment.isLiked = true
                    } else subComment.isLiked = false
                  }
                }
              }
            }
          this.videoFeed.push(feed);
        }
      }
    }
  },
  
  mounted() {
    this.wasSidebarOpen = this.$store.state.reduceButton;
    this.$store.commit("TOGGLE_REDUCE_BUTTON", true);
    this.$store.dispatch("follow/fetchFollowers");
    this.$store.dispatch("follow/fetchFollowings");
  },
  created() {
    this.dataLoaded = false;
    window.addEventListener("scroll", this.handleScroll);    
    this.getCourseFeed()
  },
  beforeDestroy() {
    if (!this.wasSidebarOpen) this.$store.commit("TOGGLE_REDUCE_BUTTON", false);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>


<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";

.vx-card .vx-card__collapsible-content.vs-con-loading__container {
  overflow: unset !important;
}

#button-load-more-posts {
  width: 200px;
  height: 80px;
  justify-content: center;
  align-items: center;
}
</style>
