<template>
  <vs-popup class="book-now-popup" title="Book sessions" :active.sync="active">
    <div class="mt-5">
      <form-wizard
        ref="tabContentRef"
        color="rgba(var(--vs-primary), 1)"
        :title="null"
        :subtitle="null"
        finishButtonText="Checkout"
        @on-complete="submit"
        @on-error="handleErrorMessage"
      >
        <tab-content
          title="Step 1"
          class="mb-5"
          :beforeChange="validateStepOne"
        >
          <!-- tab 1 content -->
          <div class="vx-row">
            <div class="vx-col w-full">
              <h4 class="mb-2">Select timeslots</h4>
              <p>
                Select available timeslot to schedule learning sessions with this
                educator.
              </p>
            </div>
          </div>
          <div class="vx-row mt-base justify-center">
            <div class="vx-col">
              <datepicker
                ref="datepickerRef"
                :inline="true"
                :disabled-dates="disabledDates"
                v-model="datetime"
                @selected="handleDateSelected"
              ></datepicker>
            </div>
            <div class="vx-col md:w-1/3 mt-4 md:mt-0">
              <h5>{{ session }} minutes session</h5>
              <p :class="timeslots.length === 0 ? '' : 'hidden'">
                Sorry, no available timeslot on this date.
              </p>
              <div
                class="time-slot"
                :class="timeslots.length === 0 ? 'hidden' : ''"
              >
                <div
                  class="
                    border
                    rounded-sm
                    border-red
                    cursor-pointer
                    hover:bg-red
                    hover:text-white
                    text-center
                    p-2
                    my-2
                    mr-2
                  "
                  :class="item.isSelected ? 'bg-red text-white' : ''"
                  v-for="(item, index) in timeslots"
                  :key="index"
                  @click="handleTimeSlotClicked(item, index)"
                >
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </tab-content>

        <!-- tab 2 content -->
        <tab-content title="Step 2" class="mb-5">
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <h4 class="mb-2">Purpose of this session</h4>
              <p>Whats your purpose/goal of this learning session?</p>
            </div>

            <div
              class="vx-col w-full sm:w-1/2 md:w-1/2"
              v-for="(item, index) in purposeList"
              :key="index"
            >
              <b-link @click="handlePurposeSelect(item)">
                <div
                  class="es-select-reason"
                  :class="item.isSelected ? 'is-selected' : ''"
                >
                  <feather-icon
                    :icon="item.icon"
                    svgClasses="h-10 w-10"
                    class="mb-2"
                  />
                  <h5>{{ item.text }}</h5>
                </div>
              </b-link>
            </div>

            <div class="vx-col w-full mt-5">
              <vs-textarea label="Message to educator:" v-model="message" />
            </div>
          </div>
        </tab-content>

        <!-- tab 3 content -->
        <!-- <tab-content
          title="Step 3"
          class="mb-5"
          :beforeChange="validateStepThree"
        >
          <div class="vx-row mb-5">
            <div class="vx-col w-full">
              <h4 class="mb-2">Great, one more step to go!</h4>
              <p>Select any of the one pricing packages below.</p>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-3/5">
              <vs-card
                class="w-full mt-4 cursor-pointer"
                :class="
                  item.isSelected ? 'bg-red text-white' : 'bg-white text-black'
                "
                v-for="(item, index) in packages"
                :key="index"
              >
                <div @click="handleSelectPackage(item, index)">
                  <h5 class="font-semibold">Session name</h5>
                  <p class="">No of Sessions: <span>3</span></p>
                  <p class="">Price: <span>$100</span></p>
                  <p class="">Discount Price: <span>$20</span></p>
                </div>
              </vs-card>
            </div>
            <div class="vx-col w-2/5">
              <vx-card>
                <p class="text-grey mb-3">Coupons</p>
                <div class="flex items-center justify-between">
                  <vs-input
                    class="inputx"
                    size="small"
                    placeholder="Coupons"
                  />
                  <span class="font-medium text-primary cursor-pointer ml-3"
                    >Apply</span
                  >
                </div>
                <vs-divider />
                <p class="font-semibold mb-3">Price Details</p>
                <div class="flex justify-between mb-2">
                  <span class="text-grey">Total Price</span>
                  <span>$300</span>
                </div>
                <div class="flex justify-between mb-2">
                  <span class="text-grey">Discount</span>
                  <span class="text-success">-60$</span>
                </div>
                <div class="flex justify-between mb-2">
                  <span class="text-grey">GST</span>
                  <span>$5</span>
                </div>
                <vs-divider />
                <div class="flex justify-between font-semibold mb-3">
                  <span class="h5 font-semibold mb-0">Total</span>
                  <span class="h5 font-semibold mb-0">$245</span>
                </div>
              </vx-card>
            </div>
          </div>
        </tab-content> -->

        <!-- tab 4 content -->
        <tab-content title="Step 2" class="mb-5">
          <div class="vx-row mb-5">
            <div class="vx-col w-full">
              <h4 class="mb-2">Final step to book your sessions</h4>
              <p>Provide your card details to process the payment, add coupons if you got any for discounts.</p>
            </div>
          </div>

          <div class="vx-row">
            <div  class="vx-col w-full lg:w-3/5">
               <vx-card  v-if="selectPackage.price == 0 && selectPackage.discountPrice == 0" class="mb-base">
                <div class="">
                  <h5>Payment Options</h5>
                    <vs-divider />
                   <div class="ml-12 mt-12" >
                      <p>                    
                        <feather-icon icon="SmileIcon" svgClasses="h-16 w-16" class="ml-24" />
                      </p>
                      <p class="text-black">
                        No payment required. Its a free service!
                      </p>
                   </div>
                </div>
                <div class="mt-3">                  
                </div>
              </vx-card>
              <vx-card v-else class="mb-base">
                <div class="">
                  <h5>Payment Options</h5>
                  <p class="text-grey">
                    Be sure to click on correct payment option
                  </p>
                </div>
                <div class="mt-3">
                  <ul>
                    <li>
                    <!-- CARD DETAILS -->
                    <div v-for="item in card_list" :key="item.id">
                      <div class="flex flex-wrap justify-between items-center">
                        <vs-radio v-model="paymentMethod" :vs-value="item.id">
                          <div class="flex items-center">
                            <img
                              src="@/assets/images/pages/bank.png"
                              alt="bank-logo"
                              height="40"
                              width="50"
                              class="inline-flex"
                            />
                            <span>{{ item.brand }}</span>
                            <span class="font-medium text-grey ml-2"
                              >ending in {{ item.last4 }}</span
                            >
                          </div>
                        </vs-radio>
                        <!-- <span>John Doe</span> -->
                        <span>{{ item.exp_month }}/{{ item.exp_year }}</span>
                      </div>
                    </div>
                    <div v-if="card_list.length == 0">
                      <div class="not-data-table vs-table--not-data">
                        No saved cards to show.
                      </div>
                    </div>
                     <h6 class="mt-3" style="color: rgb(184, 194, 204);">
                    Another payment method
                  </h6>
                  <vs-divider class="my-6" />
                  </li>
                     <li>
                    <!-- vs-value="credit-debit-atmCard" -->
                    <vs-radio v-model="paymentMethod" vs-value="newcard"
                      >Add credit/debit card</vs-radio
                    >
                    <span class="row" >
                      <span style="font-size: 13px;" class="col-10 ml-2 mb-3"
                        >We’ll save this card for your convenience. Remove it by
                        going to Your Account section.</span
                      >
                    </span>
                     <stripe-element-card
                        ref="stripeRef"
                        :pk="publishableKey"
                        @token="tokenCreated"
                      />
                  </li>
                   <!-- OPTION 1 -->
                  
                  </ul>
                </div>
              </vx-card>
            </div>

            <div class="vx-col w-full lg:w-2/5">
              <vx-card>
                <p v-if="selectPackage.price != 0 && selectPackage.discountPrice != 0" class="text-grey mb-3">Coupons</p>
                <div v-if="selectPackage.price != 0 && selectPackage.discountPrice != 0" class="flex items-center justify-between">
                  <vs-input
                    class="inputx"
                    size="small"
                    placeholder=""
                  />
                  <span class="font-medium text-primary cursor-pointer ml-3"
                    >Apply</span
                  >
                </div>
                <vs-divider />
                <p class="font-semibold mb-3">Price Details</p>
                <div class="flex justify-between mb-2">
                  <span class="text-grey">Total Price</span>
                  <span>${{ getTotalPrice(selectPackage.price) }}</span>
                </div>
                <div class="flex justify-between mb-2">
                  <span class="text-grey">Discount</span>
                  <span class="text-success">-${{ getDiscount(selectPackage.price ,selectPackage.discountPrice) }}</span>
                </div>
                <!-- <div class="flex justify-between mb-2">
                  <span class="text-grey">GST</span>
                  <span>${{ getGst(selectPackage.price * quantity) }}</span>
                </div> -->
                <vs-divider />

                <div class="flex justify-between font-semibold mb-3">
                  <span class="h5 font-semibold mb-0">Total</span>
                  <span class="h5 font-semibold mb-0">${{ getTotal() }}</span>
                </div>
                <!-- <vs-button class="w-full font-semibold" color="success">Continue to checkout</vs-button> -->
              </vx-card>
            </div>
          </div>
        </tab-content>
      </form-wizard>
    </div>
  </vs-popup>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Datepicker from "vuejs-datepicker";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { API, graphqlOperation } from "@aws-amplify/api";
import { createEducatorSessionBooking } from "@/graphql/mutations";
import { createErrorObject, createSuccessObject } from "@/utils";
import moment from "moment";


export default {
  name: "ViewServicePopup",
  components: {
    StripeElementCard,
    flatPickr,
    Datepicker,
    FormWizard,
    TabContent,
  },
  watch: {
      active: function(val) {
          if (val) {
              this.$refs.tabContentRef.reset();
              this.$refs.datepickerRef.clearDate();
              this.$refs.stripeRef.clear();
              this.customerListsources();
          }
      }
  },
  data() {
    return {
      active: false,
      datetime: "",
      configdateTimePicker: {
        enableTime: true,
        dateFormat: "d-m-Y H:i",
      },
      disabledDates: {
        days: [],
      },
      purposeList: [
        { icon: "BriefcaseIcon", text: "Career", isSelected: false },
        { icon: "BookIcon", text: "Education", isSelected: false },
        { icon: "BookOpenIcon", text: "Lifetime learning", isSelected: false },
        { icon: "HomeIcon", text: "Business/Startup", isSelected: false },
      ],
      serviceID: "",
      purpose: "",
      message: "",
      selectTimeslots: "",
      timeslots: [],
      session: "",
      selectPackage: "",
      packages: [],
      schedule: [],
      bookings: [],
      publishableKey: process.env.VUE_APP_STRIPE_API_KEY,
      token: null,
      charge: null,
      amount:1000,
      description : '"Session booking"',
      card_list : [],
      paymentMethod : 'newcard',
      serviceUserData : {},
      title : '',
    };
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.auth.userData
    },
    quantity () {
      if(this.timeslots.length !=0){
        return this.timeslots
                .filter((item) => item.isSelected).length
      }else{
        return 0;
      }
    }
  },
  methods: {
    getGst(price) {
      let gst = (parseFloat(price ) / 11).toFixed(2);
      return Number(gst);
    }, 
    getDiscount(price,discountPrice) {
      let amount = 0;
      if(Number(this.selectPackage.discountPrice) == 0 || this.selectPackage.discountPrice == null){
        amount =  0
      }else{
        amount =  (parseFloat(price).toFixed(2) * this.quantity) - (parseFloat(discountPrice).toFixed(2) * this.quantity)
      }      
      return Number(amount)
    },
    getApplicationFeeAmount() { 
      let application_fee_amount = parseFloat(this.getTotal() * 0.15).toFixed(2);
      return Number(application_fee_amount)
    }, 
    getTotal() {
      let amount = 0;
      if(Number(this.selectPackage.discountPrice) == 0 || this.selectPackage.discountPrice == null){
        amount = parseFloat(this.selectPackage.price).toFixed(2) * this.quantity;  
      }else{
        amount =  parseFloat(this.selectPackage.discountPrice).toFixed(2) * this.quantity;
      }      
      return Number(amount);
    },
    getTotalPrice(price) {
      let amount = parseFloat(price).toFixed(2) * this.quantity;     
      return Number(amount)
    }, 
    submit () {
      if(this.selectPackage.price == 0 && this.selectPackage.discountPrice == 0){
        this.createEducatorSessionBooking();
      }else{
        if(this.paymentMethod == 'newcard'){
          this.$refs.stripeRef.submit();
        }else{
          this.formSubmitted();
        }
      }      
    }, 
    tokenCreated (token) {
      this.token = token;
      this.formSubmitted();
    },  
    async customerListsources() { 
      API.post('cpRestAPI', '/stripeaccount/customer/listsources', {
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        stripeAccountKey: this.activeUserInfo.stripeAccountKey,
      }}).then(res => {
        if(res.success == true){
          this.card_list = res.response.data;
          this.paymentMethod = this.card_list[0].id;
        }else{
          this.card_list = [];
          this.paymentMethod = 'newcard';
        }
      })
      .catch(err => {
          this.card_list = [];
      })
    },   
    async createEducatorSessionBooking() { 
      try {
        const createRes = await API.graphql(
          graphqlOperation(createEducatorSessionBooking, {
            input: {
              educatorServiceID: this.serviceID,
              user: this.$store.state.auth.userData.name,
              dates: [new Date(this.datetime)],
              timeslots: this.timeslots
                .filter((item) => item.isSelected)
                .map((item) => item.text),
              purpose: this.purpose,
              message: this.message,
              packageID: this.selectPackage.id,
              createdAt: new Date(),
            },
          })
        );        
        this.sendNewSessionBookingMailToEducator();
        this.sendNewSessionBookingMailToUser();
        this.datetime = "";
        this.timeslots = "";
        this.purpose = "";
        this.message = "";
        this.packageID = "";
        this.serviceID = "";
        this.$vs.notify(
          createSuccessObject("Success", "Session has been booked")
        );
        this.active = false;
      } catch (err) {
        this.$vs.notify(
          createErrorObject("Error", "Error occurs while booking this session")
        );
        console.log(err);
      }
    },
    async sendNewSessionBookingMailToEducator() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();            
        urlencoded.append("template_code", 'session_booking');
        urlencoded.append("educator", this.serviceUserData.name);
        urlencoded.append("to", this.serviceUserData.email);
        // urlencoded.append("cc", this.$store.state.auth.userData.email);
        urlencoded.append("bcc", 'support@coursepal.app');
        urlencoded.append("session_title", this.title);
        urlencoded.append("date", new Date(this.datetime).toLocaleDateString());
        urlencoded.append("time_slots", this.timeslots
                .filter((item) => item.isSelected)
                .map((item) => item.text));
        urlencoded.append("booked_by", this.$store.state.auth.userData.displayName);
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow"
        };   
        const rawResponse = await fetch(
          // process.env.VUE_APP_BASE_URL + "/api/sendTestMail",
          `${process.env.VUE_APP_BASE_URL}/api/sendAppMail`,
          // "https://api.coursepal.app/api/sendAppMail",
          // "http://localhost:7000/api/sendAppMail",
          requestOptions
        );  
        const response = await rawResponse.json();
        if (response.status == 1) {
        }                  
    }, 
    async sendNewSessionBookingMailToUser() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();            
        urlencoded.append("template_code", 'session_booked');
        urlencoded.append("educator", this.serviceUserData.name);
        urlencoded.append("to", this.$store.state.auth.userData.email);
        urlencoded.append("bcc", 'support@coursepal.app');
        urlencoded.append("session_title", this.title);
        urlencoded.append("username", this.$store.state.auth.userData.displayName);
        urlencoded.append("date", new Date(this.datetime).toLocaleDateString());
        urlencoded.append("time_slots", this.timeslots
                .filter((item) => item.isSelected)
                .map((item) => item.text));
        urlencoded.append("booked_by", this.$store.state.auth.userData.displayName);
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow"
        };   
        const rawResponse = await fetch(
          // process.env.VUE_APP_BASE_URL + "/api/sendTestMail",
          `${process.env.VUE_APP_BASE_URL}/api/sendAppMail`,
          // "https://api.coursepal.app/api/sendAppMail",
          // "http://localhost:7000/api/sendAppMail",
          requestOptions
        );  
        const response = await rawResponse.json();
        if (response.status == 1) {
        }                  
    }, 
    async formSubmitted() { 
      API.post('cpRestAPI', '/sessionbooking/create', {
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        amount:this.getTotal(),
        token: this.token,
        description: "Session booking",
        full_name: this.activeUserInfo.name,
        email: this.activeUserInfo.email,
        stripeAccountKey: this.activeUserInfo.stripeAccountKey,
        paymentMethod:this.paymentMethod,
        application_fee_amount : this.getApplicationFeeAmount(),
        connected_stripe_account_id : this.serviceUserData.stripeConnectedAccountKey,
        input: {
              educatorServiceID: this.serviceID,
              user: this.$store.state.auth.userData.name,
              dates: [new Date(this.datetime)],
              timeslots: this.timeslots
                .filter((item) => item.isSelected)
                .map((item) => item.text),
              purpose: this.purpose,
              message: this.message,
              packageID: this.selectPackage.id,
              createdAt: new Date()
            },
    }}).then(res => {     
        this.sendNewSessionBookingMailToEducator();
        this.sendNewSessionBookingMailToUser();
        this.datetime = "";
        this.timeslots = "";
        this.purpose = "";
        this.message = "";
        this.packageID = "";
        this.serviceID = "";
        this.$vs.notify(
          createSuccessObject("Success", "Session has been booked")
        );
        this.active = false;
    })
    .catch(err => {
     this.$vs.notify(
          createErrorObject("Error", "Error occurs while booking this session")
        );
        console.log(err);
    })
      // try {
      //   const createRes = await API.graphql(
      //     graphqlOperation(createEducatorSessionBooking, {
      //       input: {
      //         educatorServiceID: this.serviceID,
      //         user: this.$store.state.auth.userData.name,
      //         dates: [new Date(this.datetime)],
      //         timeslots: this.timeslots
      //           .filter((item) => item.isSelected)
      //           .map((item) => item.text),
      //         purpose: this.purpose,
      //         message: this.message,
      //         packageID: this.selectPackage.id,
      //         createdAt: new Date(),
      //       },
      //     })
      //   );
      //   this.datetime = "";
      //   this.timeslots = "";
      //   this.purpose = "";
      //   this.message = "";
      //   this.packageID = "";
      //   this.serviceID = "";
      //   this.$vs.notify(
      //     createSuccessObject("Success", "Session has been booked")
      //   );
      //   this.active = false;
      // } catch (err) {
      //   this.$vs.notify(
      //     createErrorObject("Error", "Error occurs while booking this session")
      //   );
      //   console.log(err);
      // }
    },
    handleErrorMessage(msg) {
      if (msg) {
        this.$vs.notify(createErrorObject("Error", msg));
      }
    },
    handleSelectPackage(item, index) {
      this.packages.map((item) => (item.isSelected = false));
      item.isSelected = true;
      this.packages.splice(index, 1, item);
      this.selectPackage = item;
    },
    handlePurposeSelect(item) {
      this.purposeList.map((item) => (item.isSelected = false));
      item.isSelected = true;
      this.purpose = item.text;
    },
    isSelectSameDate (date) {
      const selectedDate = moment(date)
      let sameDateArr = []
      for (const bIndex in this.bookings) {
        const booked = moment(this.bookings[bIndex].dates[0])
        if (moment.duration(booked.diff(selectedDate)).days() === 0) {
          sameDateArr.push(bIndex)
        }
      }
      return sameDateArr
    },
    handleDateSelected(e) {
      const selectSchedule = this.schedule.filter((item) => {
        return item.day === parseInt(moment(e).format("d"));
      })[0];
      const bookedDate = this.bookings.map(item => {
        return  moment(item.dates[0])
      })
      
      const selectedDate = moment(e.toString())
      const sameDateArr = this.isSelectSameDate(selectedDate)
      const format = "HH:mma";
      const fromTime = moment(selectSchedule.from, format);
      const toTime = moment(selectSchedule.to, format);
      let currentTime = fromTime.clone();
      let timeslots = [];
      let compareTimeslot = []
      if (sameDateArr.length !== 0) {
        for (const sameDateIndex of sameDateArr) {
          const sameTimeslots = this.bookings[sameDateIndex].timeslots
          for (const sts of sameTimeslots) {
            if (compareTimeslot.indexOf(sts) === -1) {
              compareTimeslot.push(sts)
            }
          }
        }
      }
    
      if (moment.duration(toTime.diff(fromTime)).asMinutes() > 0) {
        while (moment.duration(toTime.diff(currentTime)).as("minutes") > 0) {
          // check if current timeslot should be added into the list
          let tsText = currentTime.format("HH:mm") + " - " + currentTime.add(moment.duration(this.session, "minutes")).format("HH:mm")
          if (sameDateArr.length !== 0) {
            if (compareTimeslot.indexOf(tsText) === -1) {
              timeslots.push({ text: tsText })
            }
          } else {
            timeslots.push({ text: tsText });
          }
        }
        this.timeslots = timeslots;
      }
    },
    handleTimeSlotClicked(item, index) {
      item.isSelected = !item.isSelected;
      this.timeslots.splice(index, 1, item);
    },
    validateStepOne() {
      console.log(this.timeslots);
      return new Promise((resolve, reject) => {
        if (this.datetime === "") {
          reject("Booking date cannot be empty");
        }
        if (this.timeslots.length === 0) {
          reject("Timeslots cannot be empty");
        }else{
          let timeSlotIsSelected = false;
          for (let index = 0; index < this.timeslots.length; index++) {
            const element = this.timeslots[index];
            if(element.isSelected !== undefined){
              if(element.isSelected === true){
                timeSlotIsSelected = true;
              }
            }          
          }
          if(timeSlotIsSelected === false){
            reject("Timeslots cannot be empty");
          }
        }        
        resolve(true);
      });
    },
    validateStepThree() {
      return new Promise((resolve, reject) => {
        if (this.selectPackage === "") {
          reject("Package cannot be empty");
        }
        resolve(true);
      });
    },
  },
};
</script>

<style lang="scss">
.time-slot .badge {
  padding: 0.5rem;
  color: #dc123e;
  font-size: 14px;
  border: 1px solid #dc123e;
  margin-right: 6px;
  margin-bottom: 6px;
}
.time-slot .badge:hover {
  background-color: #dc123e;
  color: #fff;
}
.time-slot {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 230px;
}
.es-select-reason {
  border: 1px solid #ccc;
  padding: 16px;
  text-align: center;
  margin-top: 16px;
}
.es-select-reason:hover {
  border: 1px solid #dc123e;
}
.es-questions-select {
  padding: 12px 16px;
  border: 1px solid #ccc;
  width: 100%;
  margin-top: 14px;
}
.es-questions-select:hover {
  border: 1px solid #dc123e;
}
.is-selected {
  border: 1px solid #dc123e;
}
.es-success {
  padding: 14px;
  background-color: rgba(40, 199, 111, 0.08);
  color: rgb(40, 199, 111);
  border-radius: 4px;
  margin-top: 16px;
  text-align: center;
}
.book-now-popup .wizard-footer-right button:last-child {
  background-color: rgb(40, 199, 111) !important;
  border-color: rgb(40, 199, 111) !important;
}
.book-now-popup .vs-card--content {
  font-size: 14px !important;
}
@media only screen and (max-width: 600px) {
  .book-now-popup .wizard-nav, .book-now-popup .wizard-progress-with-circle{
    display: none;
  }
  .book-now-popup .vue-form-wizard .wizard-tab-content{
    padding-top: 0 !important;
  }
}
</style>
