<template>
<div class="">
<div class="hidden-sm"> 
<a href="#" class="float" id="menu-share">
<i class="fa fa fa-share my-float"></i>
</a>
<ul>
<li><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://coursepal.app/&title=Coursepal&summary=Coursepal helps you learn, network, and find job opportunities. Join us today and let’s discover opportunities together!">
<i class="fa fa-facebook my-float"></i>
</a></li>
<li><a target="_blank" href="https://www.linkedin.com/shareArticle?mini=true&url=https://coursepal.app/&title=Coursepal&summary=Coursepal helps you learn, network, and find job opportunities. Join us today and let’s discover opportunities together!&source=">
<i class="fa fa-linkedin my-float"></i>
</a></li>
<li><a target="_blank" href="https://twitter.com/intent/tweet?url=https://coursepal.app/&text=Coursepal helps you learn, network, and find job opportunities. Join us today and let’s discover opportunities together!">
<i class="fa fa-twitter my-float"></i>
</a></li>
<li><a target="_blank" href="whatsapp://send?text=https://coursepal.app/&title=Coursepal&summary=Coursepal helps you learn, network, and find job opportunities. Join us today and let’s discover opportunities together!&source=">
<i class="fa fa-whatsapp my-float"></i>
</a></li>
<!-- <li><a @click="popupActive=true" href="javascript:void(0)">
<i class="fa fa fa-share my-float"></i>
</a></li>  -->
</ul>
</div>

<!-- Refer & Earn Popup -->
<vs-popup class="holamundo" title="Refer & Earn" :active.sync="popupActive">
    <div class="refer-earn p-5">
		<div class="vx-row text-center">
		<div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-4 md:mb-0 lg:mb-0">
		<div class="flex justify-center mb-4">
		<div class="re-icon">
		<feather-icon icon="SendIcon" size="large" svgClasses="stroke-current text-primary" />
		</div>
		</div>
		<h5>Send Invitation</h5>
		<p>Send your referral link to your friends for signing up in Coursepal</p>
		</div>
		<div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-4 md:mb-0 lg:mb-0">
		<div class="flex justify-center mb-4">
			<div class="re-icon">
		<feather-icon icon="ThumbsUpIcon" size="large" svgClasses="stroke-current text-primary" />
			</div>
		</div>
		<h5>Registration</h5>
		<p>Bring them on board and become active community member</p>
		</div>
		<div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-4 md:mb-0 lg:mb-0">
		<div class="flex justify-center mb-4">
			<div class="re-icon">
		<feather-icon icon="GiftIcon" size="large" svgClasses="stroke-current text-primary" />
			</div>
		</div>
		<h5>Earn Rewards</h5>
		<p>Bring 25 friends onboard and achieve 'Community Builder' badge and earn gift coupons!</p>
		</div>
	</div>
	<hr class="re-hr">
	<div class="vx-row mt-5">
	 <div class="vx-col w-full mb-base">
		<h4 class="mb-3">Invite your friends</h4>
		<label>Enter your friend’s email address and invite them to join Coursepal</label>
		<div class="flex items-center">
      <vs-input class="w-full mr-3" label="" v-model="input9" />
	  <vs-button color="primary" type="filled">Send</vs-button>
		</div>
    </div>
	<div class="vx-col w-full">
		<h4 class="mb-3">Share the referral link</h4>
		<label>You can also copy and send it or share it on your social media.</label>
		<div class="lg:flex lg:items-center">
      <!-- <vs-input class="w-2/3 mr-3" placeholder="https://coursepal.app/" label="" v-model="input9" /> -->
	  <vx-input-group class="share-referral-link w-full w-2/3 mr-2">
      <vs-input class="srl-input" placeholder="https://coursepal.app/" v-model="input4" />

      <template slot="append">
        <div class="append-text btn-addon">
          <vs-button color="primary" type="border">Copy Link</vs-button>
        </div>
      </template>
    </vx-input-group>
	<div class="flex mt-3 md:mt-0 lg:mt-0">
	  <!-- <vs-button color="primary" type="filled"><span class="text-nowrap">Copy Link</span></vs-button> -->
	  <vs-button color="#00aaff" icon-pack="feather" icon="icon-twitter" class="ml-2"></vs-button>
      <vs-button color="#0a66c2" icon-pack="feather" icon="icon-linkedin" class="ml-2"></vs-button>
	  <vs-button color="#1877f2" icon-pack="feather" icon="icon-facebook" class="ml-2"></vs-button>
	</div>
		</div>
    </div>
	</div>

	</div>
</vs-popup>
</div>
</template>

<script>
export default {
  data() {
    return {
      popupActive: false,
    }
  }
}
</script>

<style lang="scss" scoped>

.label-container{
	position:fixed;
	bottom:48px;
	right:105px;
	display:table;
	visibility: hidden;
}

.label-text{
	color:#FFF;
	background:rgba(51,51,51,0.5);
	display:table-cell;
	vertical-align:middle;
	padding:10px;
	border-radius:3px;
}

.label-arrow{
	display:table-cell;
	vertical-align:middle;
	color:#333;
	opacity:0.5;
}

.float{
	position:fixed;
	width:60px;
	height:60px;
    // bottom:40px;
    bottom:96px;
    // right:40px;
    right: 30px;
	background-color:#dc123e;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
	z-index:1000;
	animation: bot-to-top 2s ease-out;
}

ul{
	position:fixed;
    // right:40px;
    right: 30px;
	padding-bottom:20px;
    // bottom:80px;
    bottom:140px;
	z-index:100;
}

ul li{
	list-style:none;
	margin-bottom:10px;
}

ul li a{
	background-color:#dc123e;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
	width:60px;
	height:60px;
	display:block;
}

ul:hover{
	visibility:visible!important;
	opacity:1!important;
}


.my-float{
	font-size:24px;
	margin-top:18px;
}

a#menu-share + ul{
  visibility: hidden;
}

a#menu-share:hover + ul{
  visibility: visible;
  animation: scale-in 0.5s;
}

a#menu-share i{
	animation: rotate-in 0.5s;
}

a#menu-share:hover > i{
	animation: rotate-out 0.5s;
}

@keyframes bot-to-top {
    0%   {bottom:-40px}
    50%  {bottom:40px}
}

@keyframes scale-in {
    from {transform: scale(0);opacity: 0;}
    to {transform: scale(1);opacity: 1;}
}

@keyframes rotate-in {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

@keyframes rotate-out {
    from {transform: rotate(360deg);}
    to {transform: rotate(0deg);}
}

@media only screen and (max-width: 600px) {
.float{
    right:15px !important;
}

ul{
    right:15px !important;
}

}

.share-referral-link{

}
.vx-input-group .vx-input-group-append .append-text.btn-addon .vs-button {
    border-radius: 0 !important;
	border-left: 0 !important;
	border: 1px solid rgba(0, 0, 0, 0.2) !important;
}
.re-icon{
	width: 64px;
	height: 64px;
	border-radius: 50%;
	background: rgba(var(--vs-primary),.08) !important;
	align-items: center !important;
	justify-content: center !important;
	display: flex !important;
}
.re-hr{
	margin-top: 20px;
	margin-bottom: 32px;
}
</style>