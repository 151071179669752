<template>
  <vx-card
    title="Welcome to MyPals!"
    remove-card-action
    no-shadow
    title-color="#212121"
    card-background="#609bfc"
    content-color="#fff"
    @remove="handleCardRemove"
    class="mt-base feedinfo"
  >
    <div class="carousel-example">
      <!-- swiper -->
      <swiper
        :options="swiperOption"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
        :key="$vs.rtl"
      >
        <div
          class="parallax-bg"
          slot="parallax-bg"
          data-swiper-parallax="-23%"
        ></div>
        <swiper-slide>
          <!-- <div class="title" data-swiper-parallax="-100">Slide 1</div> -->
          <div class="subtitle font-normal mb-1" data-swiper-parallax="-200">
            Public feed:
          </div>
          <div class="text" data-swiper-parallax="-300">
            <p class="font-medium mb-2">
              Public feed helps to connect with other members in our community!
            </p>
            <p class="font-small">
              Start discussing new thoughts!
            </p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <!-- <div class="title" data-swiper-parallax="-100">Slide 2</div> -->
        <!--   <div class="subtitle font-semibold mb-2" data-swiper-parallax="-200">
            Unifeed
          </div>
          <div class="text" data-swiper-parallax="-300">
            <p class="font-medium mb-2">
              Unifeed helps you to connect with your friends in University!
            </p>
            <p class="font-small">
              Start sharing Coursepal app with your friends to grow our community
            </p>
          </div>
        </swiper-slide> <!-- 
        <swiper-slide>
          <!-- <div class="title" data-swiper-parallax="-100">Slide 3</div> -->
          <div class="subtitle font-normal mb-2" data-swiper-parallax="-200">
            Learning Groups:
          </div>
          <div class="text" data-swiper-parallax="-300">
            <p class="font-small mb-2">
              Learning Groups help you to connect with like-minded people and discuss new thoughts!
            </p>
          </div>
        </swiper-slide>
     <!--    <swiper-slide>
          <div class="title" data-swiper-parallax="-100">Slide 4</div>
          <div class="subtitle font-semibold mb-2" data-swiper-parallax="-200">
            One-one Sessions
          </div>  
          <div class="text" data-swiper-parallax="-300">
            <p class="font-small mb-2">
            You can book one-one video session with educators for personalised learning.
            </p>
            <p class="font-medium">
              You can book sessions at Educator profile page, Coursefeed communities and Sessions page.
            </p>
          </div> -->
        </swiper-slide>
        <swiper-slide>
          <!-- <div class="title" data-swiper-parallax="-100">Slide 4</div> -->
          <div class="subtitle font-semibold mb-2" data-swiper-parallax="-200">
            Newsfeeds
          </div>
          <div class="text" data-swiper-parallax="-300">
            <p class="font-small mb-2">
              News Feeds help you to get the latest news and read interesting articles.
            </p>
            <p class="font-medium">
              You can follow more newsfeeds by clicking on 'More' button at tabs section
            </p>
          </div>
        </swiper-slide>

        <div
          class="swiper-pagination swiper-pagination-white"
          slot="pagination"
        ></div>
        <!-- <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-white" slot="button-next"></div> -->
      </swiper>
    </div>
  </vx-card>
</template>

<script>
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  data () {
    return {
      swiperOption: {
        speed: 600,
        parallax: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  components: {
    swiper,
    swiperSlide
  },
  methods: {
    handleCardRemove () {
      this.$store.dispatch('auth/updateIntro')
    }
  }
}
</script>

<style scoped>
.swiper-slide {
  font-size: 12px;
  /* color: #3851A5; */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* padding: 40px 60px; */
  /* background-color:  !important; */
  justify-content: space-around !important;
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  /* background-image: url('../../../../assets/images/slider/04.jpg') */
}

.swiper-slide .title {
  font-size: 41px;
  font-weight: 300;
}

.swiper-slide .subtitle {
  font-size: 20px;
}

.swiper-slide .text {
  font-size: 14px;
  /* max-width: 400px; */
  line-height: 1.3;
}
.carousel-example .text {
  margin-bottom: 40px;
}
</style>
        